import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 md:mr-8">
          <blockquote className="border-l-4 border-gray-900 font-serif leading-loose pl-4 text-justify">
            Hi! I'm a software developer from the greater Boston area.
            I work as a software developer at a start-up and I created 
            this app in my spare time. I love machine learning, 
            algorithm design, and data science.
            <br></br>
            <br></br>
            If you find the app useful, please consider donating. Every 
            request made to the app takes money out of my pocket so 
            every donation really means a lot and helps keep this 
            website up and running!
          </blockquote>

          <cite className="font-bold mt-4 text-right text-xs uppercase block">
          Made with ❤ and a lot of headache
          </cite>
          <cite className="font-bold mt-4 text-right text-xs uppercase block">
           -Brian
          </cite>
        </div>

        <figure className="w-2/3 md:w-1/3">
          <img src={dogIllustration} alt="A dog relaxing" />
        </figure>
      </section>
    </Layout>
  );
}

export default AboutPage;
